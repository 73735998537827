import 'shepherd.js/dist/css/shepherd.css'

import type { IssueReporterConfig } from '@asta/issue-reporter'
import { IssueReporterPackage } from '@asta/issue-reporter'
import {
	ColorSchemeProvider,
	ErrorBoundary,
	ThemeCustomizationWrapper,
} from '@asta/react-component-library'
import Snackbar from 'components/@extended/Snackbar'
import { AuthProvider } from 'contexts/auth'
import { BreadcrumbsProvider } from 'contexts/breadcrumbs'
import { SelectedAppProvider } from 'contexts/selectedApp'
import { WorkspaceProvider } from 'contexts/workspace'
import RTLLayout from 'deprecated/components/RTLLayout'
import ScrollTop from 'deprecated/components/ScrollTop'
import ErrorPage from 'pages/ErrorPage'
import { Toaster } from 'react-hot-toast'
import Routes from 'routes'
import ThemeCustomization from 'themes'

//TODO: These should come from the .env file
const issueReporterConfig: IssueReporterConfig = {
	token: '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',
	organization: 'BPS-Consulting',
	repository: 'asta',
	'x-api-key': 'AprdMWbKSCXGnYVb',
}

const App = (): JSX.Element => {
	return (
		<ColorSchemeProvider>
			<RTLLayout>
				<ThemeCustomization>
					<ThemeCustomizationWrapper>
						<AuthProvider>
							<WorkspaceProvider>
								<SelectedAppProvider>
									<ScrollTop>
										<BreadcrumbsProvider>
											<ErrorBoundary Fallback={ErrorPage}>
												<Toaster />
												<Routes />
												<Snackbar />
												{/* <IssueReporterPackage
													config={issueReporterConfig}
												/> */}
											</ErrorBoundary>
										</BreadcrumbsProvider>
									</ScrollTop>
								</SelectedAppProvider>
							</WorkspaceProvider>
						</AuthProvider>
					</ThemeCustomizationWrapper>
				</ThemeCustomization>
			</RTLLayout>
		</ColorSchemeProvider>
	)
}

export default App
